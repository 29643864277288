import _ from 'lodash';
import api from '@/api';
import base64toPDF from '../../lib/base64ToPDF';
import Cookies from '@/portal-cookie';
import { message } from 'antd';

const initialState = {
	invoices: [],
	selectedForPayment: [],
	paymentErrors: [], // errors from authorize.net when attemping to capture payment
	invoicePaymentTotal: 0,
	cardPaymentFeesTotal: 0,
	processingPayment: false,
	processingSuccess: false,
	processingErrors: [], // errors occured when writing to SAP w/ IncomingPayment doc
	successfulTransactionId: '', // this comes from authorize.net
	cardCode: '',
	cardType: '',
	cardName: '',
	loading: false,
	loaded: false,
	loadedVendorCode: false,
	awaitingAcceptToken: false,
	acceptHostedToken: null,
	allowPaymentIframe: true,
	paymentTypeSelected: false,
	showInvoicePaymentDialog: false,
	showPreviewPDFDialog: false,
	showContactDialog: false,
	pdfModalLoading: false,
	pdfModalData: null,
	pdfFilePreview: null,
	pdfModalKey: null,
	totalInvoiceDue: null,
	totalCredit: null,
	customerProfileId: null, // TODOPAY not being used anywhere yet
	customerProfileId_from_SAP: null,
	storePayment: true, // V1 WAS false
	responseCode: null
};

export const types = {
	SET_STATE: 'INVOICE/SET_STATE',
	SET_SELECTED: 'INVOICE/SET_SELECTED',
	UNSET_SELECTED: 'INVOICE/UNSET_SELECTED',
};

const reducers = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.SET_SELECTED:
			return {
				...state,
				selectedForPayment: [
					...state.selectedForPayment,
					action.data,
				]
			};

		case types.UNSET_SELECTED:
			return {
				...state,
				selectedForPayment: state.selectedForPayment
					.filter((record) => record.DocEntry !== action.data.DocEntry)
					.map((record) => ({ ...record }))
			};
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	resetContactDialog: () => ({
		type: types.SET_STATE, data: {
			showContactDialog: false,
		},
	}),

	resetInvoicePaymentDialog: () => ({
		type: types.SET_STATE, data: {
			showInvoicePaymentDialog: false,
			invoicePaymentTotal: 0,
			cardPaymentFeesTotal: 0,
			processingPayment: false,
			processingSuccess: false,
			processingErrors: [],
			successfulTransactionId: '',
			awaitingAcceptToken: false,
			acceptHostedToken: null,
		},
	}),

	resetInvoicePDFDialog: () => ({
		type: types.SET_STATE, data: {
			showInvoicePDFDialog: false,
		},
	}),

	reset: () => ({ type: types.SET_STATE, data: { ...initialState } }),

	setSelected: (record) => ({ type: types.SET_SELECTED, data: record }),

	unsetSelected: (record) => ({ type: types.UNSET_SELECTED, data: record }),

	loadInvoices: () => async (dispatch, getState) => {
		console.log('[Invoices.redux] loadInvoices');
		// TODOPAY BUG modal doesnt work after submissions - this happens after closing modal
		// TODOPAY BUG also the modal breaks after any submissions errors whatsoever 
		const state = getState(),
			loading = state.invoices.loading,
			cardCode = state.invoices.cardCode,
			cardType = state.invoices.cardType;

		//console.log(cardCode)
		//console.log(cardType)
		if (loading) {
			return false;
		}

		dispatch(actions.setState({ loading: true, loaded: false }));

		try {
			let response = await api.get('/invoices', {
				params: {
					cardCode,
					cardType
				},
			});

			const _invoices = response[0];
			//console.log(_invoices)

			const totalInvoiceDue = response[1][0]['DocTotalOpen'];
			const totalCredit = response[2][0]['DocTotalCredit'];
			// const invoices = response.map((invoice) => {
			// 	return { ...invoice, 'PayAmount': invoice.DocumentTotal };
			// });

			const cardNameProp = cardType === 'C' ? 'CustomerName' : 'VendorName';

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: _invoices,
					cardName: response[0].length > 0
						? response[0][0][cardNameProp]
						: '',
					totalInvoiceDue: totalInvoiceDue,
					totalCredit: totalCredit,
					allowPaymentIframe: true,
					paymentTypeSelected: false
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loaded: true,
					invoices: [],
					cardName: '',
				},
			});
		}
	},

	loadVendorCode: () => async (dispatch) => {
		console.log('[Invoices.redux] loadVendorCode')
		try {
			const userKey = Cookies.get('TS_UserKey', '');
			const response = await api.get('/invoices/vendor-code', {
				params: {
					userKey: userKey,
				},
			});

			console.log('CustomerProfileId');
			console.log(response.CustomerProfileId);

			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode: true,
					cardCode: response.BPCode,
					cardType: response.BPType,
					customerProfileId_from_SAP: response.CustomerProfileId,
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loading: false,
					loadedVendorCode: true,
					cardCode: '',
					cardType: '',
				},
			});
		}
	},

	loadInvoicePreview: (docEntry, objType) => async (dispatch) => {
		console.log('[invoices.redux] loadInvoicePreview  docEntry: ' + docEntry);
		dispatch(actions.setState({ pdfModalLoading: true }));
		try {
			const response = await api.get('/invoices/preview', {
				params: {
					docEntry,
					objType
				}
			});

			let fileData = response.FileData;
			let pdf = base64toPDF(fileData);

			dispatch(actions.setState({ pdfModalKey: docEntry }))
			dispatch(actions.setState({ pdfModalData: fileData }));
			dispatch(actions.setState({ pdfFilePreview: pdf }));
			dispatch(actions.setState({ pdfModalLoading: false }));
		} catch (e) {
			console.error(e);
			message.error('Could not load invoice preview');
			dispatch(actions.setState({ pdfModalLoading: false }));
		}
	},

	processPayment: (paymentForm) => async (dispatch, getState) => {
		console.log('[Invoices.redux] processPayment')
		// should send invoice keys, query and confirm totals match
		const state = getState(),
			cardCode = state.invoices.cardCode,
			cardName = state.invoices.cardName,
			storePayment = state.invoices.storePayment,
			invoices = state.invoices.invoices,
			invoiceDocNums = invoices.filter((invoice) => invoice.Checked).map(invoice => invoice.DocNum),
			invoicePaymentTotal = state.invoices.invoicePaymentTotal,
			cardPaymentFeesTotal = state.invoices.cardPaymentFeesTotal;


		console.log(paymentForm)
		//   console.log(invoices.invoices);

		dispatch(actions.setState({ processingPayment: true }));
		try {

			let paymentResults = await api.post('/payments/capture', {
				paymentForm,
				invoicePaymentTotal,
				cardPaymentFeesTotal,
				cardCode,
				cardName,
				storePayment,
				invoiceDocNums
			});

			console.log('paymentResults');
			console.log(paymentResults);

			// DUMMY {
			// 	"successful": true,
			// 	"paymentData": {
			// 		"paymentType": "CC",
			// 		"paymentContact": "Allen David",
			// 		"paymentAcctType": "Visa",
			// 		"transactionId": "120041836101",
			// 		"paymentTotal": "51.45"
			// 	}
			// }

			if (paymentResults.successful) {
				console.log('paymentResults successful');
				// set pending payment cookie
				let pendingCookie = Cookies.get('pendingInvoices'),
					currentPending = null;
				if (pendingCookie) {
					currentPending = JSON.parse(decodeURIComponent(Cookies.get('pendingInvoices')));
				}

				if (currentPending) {
					const newPending = _.union(currentPending, invoiceDocNums);
					Cookies.set('pendingInvoices', JSON.stringify(newPending));
				} else {
					Cookies.set('pendingInvoices', JSON.stringify(invoiceDocNums));
				}

				dispatch(actions.setState({ responseCode: paymentResults.responseCode }));

				// need to make sure all info is sent, the final payment res does not have a lot
				dispatch(actions.recordPaymentToSAP(paymentResults.paymentData));

				// check if customer Profile is saved, store in state, and record to SAP
				if (paymentResults.customerProfileId) {
					dispatch(actions.setState({ customerProfileId: paymentResults.customerProfileId }));
					dispatch(actions.recordCustomerProfileIdToSAP(paymentResults.customerProfileId));
				}
			} else {
				console.log('paymentResults NOT successful');
				console.log('paymentResults.responseCode');
				console.log(paymentResults.responseCode);
				dispatch(actions.setState({ responseCode: paymentResults.responseCode }));
			}
		} catch (e) {
			//console.error('it done went wrong', e);
			message.error(e.response.data.errors);
			dispatch(actions.setState({
				processingPayment: false,
				processingSuccess: false,
				paymentErrors: [...e.response.data.errors],
			}));
		}
	},

	getAcceptToken_V2: (payType) => async (dispatch, getState) => {
		console.log('[Invoices.redux] getAcceptToken_V2');

		const state = getState(),
			cardCode = state.invoices.cardCode,
			cardName = state.invoices.cardName,
			storePayment = state.invoices.storePayment,
			invoicePaymentTotal = state.invoices.invoicePaymentTotal,
			invoices = state.invoices.invoices,
			invoiceDocNums = invoices.filter((invoice) => invoice.Checked).map(invoice => invoice.DocNum),
			customerProfileId_from_SAP = state.invoices.customerProfileId_from_SAP,
			cardPaymentFeesTotal = state.invoices.cardPaymentFeesTotal;

		//console.log('[Invoices.redux] getAcceptToken_V2  invoicePaymentTotal ', invoicePaymentTotal);
		//console.log('[Invoices.redux] getAcceptToken_V2  cardPaymentFeesTotal ', cardPaymentFeesTotal);

		try {

			dispatch(actions.setState({
				acceptHostedToken: null,
				awaitingAcceptToken: true,
			}));

			const _response = await api.get('/payments/get-accept-token', {
				params: {
					paymentType: payType,
					storePayment: storePayment,
					customerProfileId: customerProfileId_from_SAP,
					invoiceDocNums: invoiceDocNums,
					//customerEmail: "development@tiger-sheep.com",
					invoicePaymentTotal: invoicePaymentTotal,
					cardPaymentFeesTotal: cardPaymentFeesTotal,
					cardCode: cardCode,
					cardName: cardName
				}
			});
			//console.log(_response.response);
			//console.log(_response.response.token);
			if (_response.response.token) { // -OR- _response.response.messages.resultCode = 'Ok'
				console.log('[Invoices.redux] getAcceptToken_V2  successful');
				dispatch(actions.setState({
					acceptHostedToken: _response.response.token,
					awaitingAcceptToken: false,
					cardPaymentFeesTotal: cardPaymentFeesTotal,
				}));
			} else {
				console.log('[Invoices.redux] getAcceptToken_V2  unsuccessful ELSE');
				dispatch(actions.setState({
					acceptHostedToken: null,
					awaitingAcceptToken: false,
				}));
			}

		} catch (err) {
			console.error('[Invoices.redux] getAcceptToken_V2 catch err');
			console.error(err);
			message.error('Could not fetch Authorize.Net token');
			dispatch(actions.setState({
				acceptHostedToken: null,
				awaitingAcceptToken: false,
			}));
		}
	},
	processPayment_V2: (authorizePaymentResponse) => async (dispatch, getState) => {
		console.log('[Invoices.redux] processPayment_V2');
		console.log(authorizePaymentResponse);

		const state = getState(),
		invoices = state.invoices.invoices,
		invoiceDocNums = invoices.filter((invoice) => invoice.Checked).map(invoice => invoice.DocNum);

		// authorizePaymentResponse.accountType Visa / eCheck
		if (authorizePaymentResponse.responseCode == '1') {
			message.success('Your payment has processed successfully and has been recorded');
			dispatch(actions.setState({
				processingPayment: false,
				processingSuccess: true,
				responseCode: authorizePaymentResponse.responseCode,
				successfulTransactionId: authorizePaymentResponse.transId,
			}));

			dispatch(actions.recordPaymentToSAP_V2(authorizePaymentResponse, invoiceDocNums)); // TODO subtract fee from totalAmount
		} else {
			console.error('[Invoices.redux] processPayment_V2 BAD ')
		}

	},
	showError_V2: (authorizePaymentResponse) => async (dispatch, getState) => {
		console.log('[Invoices.redux] showError_V2');
		console.log(authorizePaymentResponse);
		// 1 -- Approved
		// 2 -- Declined
		// 3 -- Error
		// 4 -- Held for Review
		if (authorizePaymentResponse.responseCode == '2') {
			message.error('Your payment was declined');
		} else if (authorizePaymentResponse.responseCode == '3') {
			message.error('Your payment had an error');
		} else if (authorizePaymentResponse.responseCode == '4') {
			message.error('Your payment was held');
		}
		dispatch(actions.recordPaymentToSAP_V2(authorizePaymentResponse)); // WAS V1,
		dispatch(actions.setState({
			processingPayment: false,
			processingSuccess: false,
		}));

	},
	recordPaymentToSAP: (paymentData) => async (dispatch, getState) => {

		console.log('[Invoices.redux] recordPaymentToSAP');
		console.log(paymentData);

		const state = getState(),
			invoices = state.invoices.invoices,
			cardCode = state.invoices.cardCode,
			creditCardFees = state.invoices.cardPaymentFeesTotal,
			selectedInvoices = invoices.filter((invoice) => invoice.Checked),
			responseCode = state.invoices.responseCode,
			invoiceDocNums = selectedInvoices.map(invoice => invoice.DocNum) || [];


		// console.log('invoiceDocNums');
		// console.log(invoiceDocNums);
		// console.log('responseCode');
		// console.log(responseCode);

		let paymentResult;
		try {

			// write to sidetable before any SAP interaction
			const loggingResult = await api.post('/invoices/log-transaction', {
				params: {
					invoiceDocNums,
					cardCode,
					creditCardFees,
					responseCode,
				}
			});
			console.log('loggingResult');
			console.log(loggingResult);
			//console.log(loggingResult.sqlResults); // probably too technical to be useful, but might be

			if (Boolean(paymentData.paymentType === 'CC')) {
				// QUERY: Invoices.CheckForCCFee
				const invoiceCheckResults = await api.get('/invoices/audit', {
					params: {
						invoiceDocNums,
					},
				});

				console.log('invoiceCheckResults');
				console.log(invoiceCheckResults);

				if (invoiceCheckResults.invoicesLeftToCreate.length > 0) {
					const invoiceData = {
						invoiceDocNums: invoiceCheckResults.invoicesLeftToCreate.join(', '),
						cardCode,
						LineItem: {
							Item: 'CC Fee',
							Qty: 1,
							LineTotal: parseFloat(creditCardFees).toFixed(2)
						},
					};

					const invoiceResult = await api.post('/invoices/create', {
						params: {
							invoiceData
						}
					});

					console.log('invoiceResult');
					console.log(invoiceResult);

					if (invoiceResult.successful) {
						selectedInvoices.push({
							DocEntry: invoiceResult.feeInvoiceKey,
							DocumentTotal: parseFloat(creditCardFees).toFixed(2),
							ObjType: '13'  // CC Fee treated as regular invoice
						});
					} else {
						console.error(`Could not create CC fee invoice for invoice(s) with doc nums: ${invoiceCheckResults.invoicesLeftToCreate.join(', ')}`);
					}
				} else {
					console.log('NO invoicesLeftToCreate, proceeding to making payments')
				}

				// creates IncomingPayment Document
				paymentResult = await api.post('/invoices/payment', {
					params: {
						transaction: paymentData,
						invoiceDocNums: selectedInvoices,
						cardCode,
					}
				});

				console.log('paymentResult CC');
				console.log(paymentResult);
				//console.log(paymentResult.sqlResults); // probably too technical to be useful, but might be

			} else {
				// creates IncomingPayment Document
				console.log('ELSE creates IncomingPayment Document')
				paymentResult = await api.post('/invoices/payment', {
					params: {
						transaction: paymentData,
						invoiceDocNums: selectedInvoices,
						cardCode,
					}
				});

				console.log('paymentResult');
				console.log(paymentResult);
				//console.log(paymentResult.sqlResults); // probably too technical to be useful, but might be
			}

			if (paymentResult.successful) {
				// remove any doc nums that were in pending invoices and reset the cookie
				let pendingCookie = Cookies.get('pendingInvoices'),
					pendingInvoices = null;

				if (pendingCookie) {
					pendingInvoices = JSON.parse(decodeURIComponent(Cookies.get('pendingInvoices')));
				}

				if (pendingInvoices) {
					const newPendingInvoices = pendingInvoices.filter(invoice => !invoiceDocNums.includes(invoice));
					Cookies.set('pendingInvoices', JSON.stringify(newPendingInvoices));
				}

				message.success('Your payment has processed successfully and has been recorded');

				dispatch(actions.setState({
					processingPayment: false,
					processingSuccess: true,
					successfulTransactionId: paymentResult.transactionId,
				}));
			} else {

				console.log('paymentResult NOT successful');
				message.error('Your payment was processed but there was an issue recording into SAP. will be marked as Pending.');
			}

		} catch (e) {
			console.log('recordPaymentToSAP catch err');
			console.error(e)
			// console.error(e.response);
			console.error(e.response.data);
			console.error(e.response.data.errors);
			message.error('Your payment has processed but there was an issue updating the invoice. Please do not pay again.');
			dispatch(actions.setState({
				processingPayment: false,
				processingSuccess: false, // BUG2
				processingErrors: ['There was an issue recording your payment. Please do not pay again. Your payment is pending. ', e.response.data.errors]
			}));
		}
	},
	recordPaymentToSAP_V2: (paymentData, invoiceDocNums) => async (dispatch, getState) => {

		console.log('[Invoices.redux] recordPaymentToSAP_V2');
		console.log(paymentData);

		// totalAmount into DocTotal seems wrong

		const state = getState(),
			invoices = state.invoices.invoices,
			cardCode = state.invoices.cardCode,
			creditCardFees = state.invoices.cardPaymentFeesTotal,
			selectedInvoices = invoices.filter((invoice) => invoice.Checked),
			responseCode = state.invoices.responseCode;
			//invoiceDocNums = selectedInvoices.map(invoice => invoice.DocNum) || [];


		// console.log('invoiceDocNums');
		// console.log(invoiceDocNums);
		// console.log('responseCode');
		// console.log(responseCode);

		let paymentResult;
		try {

			// write to sidetable before any SAP interaction
			const loggingResult = await api.post('/invoices/log-transaction', {
				params: {
					invoiceDocNums,
					cardCode,
					creditCardFees,
					responseCode,
				}
			});
			console.log('loggingResult');
			console.log(loggingResult);
			//console.log(loggingResult.sqlResults); // probably too technical to be useful, but might be

			console.log('paymentData.accountType')
			console.log(paymentData.accountType)
			if (Boolean(paymentData.accountType === 'Visa')) {
				// QUERY: Invoices.CheckForCCFee
				const invoiceCheckResults = await api.get('/invoices/audit', {
					params: {
						invoiceDocNums,
					},
				});

				console.log('invoiceCheckResults');
				console.log(invoiceCheckResults);

				if (invoiceCheckResults.invoicesLeftToCreate.length > 0) {
					const invoiceData = {
						invoiceDocNums: invoiceCheckResults.invoicesLeftToCreate.join(', '),
						cardCode,
						LineItem: {
							Item: 'CC Fee',
							Qty: 1,
							LineTotal: parseFloat(creditCardFees).toFixed(2)
						},
					};

					const invoiceResult = await api.post('/invoices/create', {
						params: {
							invoiceData,
							creditCardFees
						}
					});

					console.log('invoiceResult');
					console.log(invoiceResult);

					if (invoiceResult.successful) {
						//console.log('IMPORTANT, adding newly created CC Fee invoice to selectedInvoices'); 
						selectedInvoices.push({
							DocEntry: invoiceResult.feeInvoiceKey,
							DocumentTotal: parseFloat(creditCardFees).toFixed(2),
							ObjType: '13'  // CC Fee treated as regular invoice
						});
					} else {
						console.error(`Could not create CC fee invoice for invoice(s) with doc nums: ${invoiceCheckResults.invoicesLeftToCreate.join(', ')}`);
					}
				} else {
					console.log('NO invoicesLeftToCreate, proceeding to making payments')
				}

				// creates IncomingPayment Document
				paymentResult = await api.post('/invoices/payment-v2', {
					params: {
						transaction: paymentData, // TODO totalAmount
						creditCardFees,
						invoiceDocNums: invoiceDocNums, // WAS selectedInvoices,
						selectedInvoices: selectedInvoices,
						cardCode,
					}
				});

				console.log('paymentResult CC');
				console.log(paymentResult);
				//console.log(paymentResult.sqlResults); // probably too technical to be useful, but might be

			} else {
				// creates IncomingPayment Document
				console.log('ELSE creates IncomingPayment Document')
				paymentResult = await api.post('/invoices/payment-v2', {
					params: {
						transaction: paymentData,
						invoiceDocNums: invoiceDocNums,
						selectedInvoices: selectedInvoices,
						cardCode,
					}
				});

				console.log('paymentResult');
				console.log(paymentResult);
				//console.log(paymentResult.sqlResults); // probably too technical to be useful, but might be
			}

			if (paymentResult.successful) {
				//console.log('paymentResult successful - remove any doc nums that were in pending invoices and reset the cookie');
				// remove any doc nums that were in pending invoices and reset the cookie
				let pendingCookie = Cookies.get('pendingInvoices'),
					pendingInvoices = null;

				if (pendingCookie) {
					pendingInvoices = JSON.parse(decodeURIComponent(Cookies.get('pendingInvoices')));
				}

				if (pendingInvoices) {
					const newPendingInvoices = pendingInvoices.filter(invoice => !invoiceDocNums.includes(invoice));
					Cookies.set('pendingInvoices', JSON.stringify(newPendingInvoices));
				}

				message.success('Your payment has processed successfully and has been recorded');

				dispatch(actions.setState({
					processingPayment: false,
					processingSuccess: true,
					successfulTransactionId: paymentResult.transactionId, // NOTE the actual payload is called transId, but kept this name in frontend
				}));
			} else {

				console.log('paymentResult NOT successful');
				message.error('Your payment was processed but there was an issue recording into SAP. will be marked as Pending.');
			}

		} catch (e) {
			console.log('recordPaymentToSAP_V2 catch err');
			console.error(e)
			// console.error(e.response);
			console.error(e.response.data);
			console.error(e.response.data.errors);

			// write to sidetable that there was an issue writing to SAP
			const sapWriteFailLogResult = await api.post('/invoices/log-sap-failure', {
				params: {
					invoiceDocNums,
					failedReasons: e.response.data.errors
				}
			});
			console.log('sapWriteFailLogResult');
			console.log(sapWriteFailLogResult);
			//console.log(sapWriteFailLogResult.sqlResults); // probably too technical to be useful, but might be

			// DONT SHOW THE USER message.error('Your payment has processed but there was an issue updating the invoice. Please do not pay again.');
			dispatch(actions.setState({
				processingPayment: false,
				//processingSuccess: false, // BUG2
				//processingErrors: ['There was an issue recording your payment. Please do not pay again. Your payment is pending. ', e.response.data.errors]
			}));
		}
	},
	// getCustomerProfileIdFromSAP: (cardCode) => async (dispatch, getState) => {

	// 	//const state				= getState();
	// 	//const cardCodeFromState			= state.invoices.cardCode;

	// 	try {
	// 		const response = await api.get('/invoices/customer-profile-id', {
	// 			params: {
	// 				cardCode, // OR cardCodeFromState
	// 			}
	// 		});
	// 		console.log(response)
	// 		if (response.successful) {
	// 			console.log('Customer Profile id stored in SAP is: TODO ');
	// 		}

	// 	} catch (e) {
	// 		console.error(e);
	// 		message.error('Could not save Customer Profile id to Authorize.net.');

	// 	}
	// },

	recordCustomerProfileIdToSAP: (customerProfileId) => async (dispatch, getState) => {
		const state = getState(),
			cardCode = state.invoices.cardCode;

		try {
			const response = await api.post('/invoices/customer-profile-id', {
				params: {
					cardCode,
					customerProfileId,
				}
			});
			if (response.successful) {
				console.log('Customer Profile id ', customerProfileId, ' is Saved to SAP.');
			}

		} catch (e) {
			console.error(e);
			message.error('Could not save Customer Profile id to Authorize.net.');

		}
	},
};
