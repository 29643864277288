import api from '@/api';
import { notification } from 'antd';
import Cookies from '@/portal-cookie';

const initialState = {
	showUserDataDialog: false,
	loading: false,
	loaded:  false,
	userData: null,
	email: '',
	name: '',
};

export const types = {
	SET_STATE: 'USERDETAIL/SET_STATE',
};

const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
	resetUserModal: () => ({
		type: types.SET_STATE, data: {
			showUserDataDialog: false,
		},
	}),
	reset: () => ({ type: types.SET_STATE, data: { ...initialState } }),

	setUserDetails: () => async (dispatch) => {
		const userKey = Cookies.get('TS_UserKey');
		console.log('userKey', userKey)
		try {
			let res = await api.get('/account/user-details', {
				params: {
					userKey
				},
			});
			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
					name: res.Name,
					email: res.E_MailL,
				},
			});
		} catch(err) {
			console.log('Error fetching user details', err)
		}
	},

	resetUserPassword: (email, password) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { loading: true } });

		try {
			let res = await api.post('/account/reset_token', {email});
			await api.post('/account/reset', {email, password, resetToken: res.Code});
			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
					loaded:       true,
					loading: false,
				},
			});
			dispatch(actions.setUserDetails());
			notification['success']({
				duration: 1,
			    message: 'Update Success!',
			    description:
			      'Changed password successfully',
			});
		} catch(err) {
			notification['error']({
				duration: 1,
			    message: 'Update failure!',
			    description:
			      'Invalid Credential',
			});
			dispatch(actions.setUserDetails());
			dispatch({
				type: types.SET_STATE,
				data: { loaded: false, loading: false },
			});
		}
	},
};
