import api from '@/api';
import Cookies from '@/portal-cookie';
import getResponseErrors from '@/lib/getResponseErrors';
import { notification } from 'antd';

const initialState = {
	loggedIn:        false,
	pending:         false,
	loading:		 false,
	showRelog:       false,
	errors:          [],
	resetToken:      null,
	verifyingToken:  false,
	//showSignupModal: false,
	showPasswordChange: false,
	email: '',
	name: '',
	loadSubmitEmailModal: false,
	loadResetPasswordModal: false,
};

export const types = {
	SET_STATE: 'AUTH/SET_STATE',
};

const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	handleLogin: (data) => async (dispatch, getState) => {
		console.log('[auth.redux] handleLogin', data);
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {
			let res = await api.post('/account/login', {
				email: data.username,
				password: data.password,
			});
			// if (res.ResetRequired == 'Yes') {
				// 	dispatch({ type: types.SET_STATE, data: { ...initialState, showPasswordChange:true } });
				// } else {
			dispatch({ type: types.SET_STATE, data: { ...initialState, loggedIn:true, email: data.username, name: res.user.Name } });
			// }
			Cookies.set('TS_User_type', res.user.TS_User_type);
			Cookies.set('TS_UserKey', res.user.TS_UserKey);
			Cookies.set('authToken', res.token);
		} catch(err) {
			notification.destroy();
			notification['error']({
				duration: 1,
			    message: 'Login failure!',
			    description:
			      'Invalid Credential',
			});
			console.log('err', err);
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	handlePasswordChange: (data) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		if(data.password_reset1 !== data.password_reset2) {
			return dispatch({
				type: types.SET_STATE,
				data: { pending: false, errors: [ 'Password fields must match.' ] },
			});
		}

		try {
			await api.post('/user/change_password', {
				token: getState().auth.resetToken,
				pass:  data.password_reset1,
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					pending:            false,
					errors:             [],
					loggedIn:           false,
					showPasswordChange: false,
					showRelog:          true,
					resetToken:         null,
				},
			});


		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	verifyToken: () => async (dispatch) => {
		console.log('[auth.redux] verifyToken');
		dispatch({ type: types.SET_STATE, data: { pending: true, loggedIn: false, verifyingToken: true, errors: [] } });

		try {
			await api.get('/user/verify_token');

			//dispatch(permissionActions.setState(res.permissions || {}));
			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
					loggedIn:       true,
					verifyingToken: false,
				},
			});

		} catch(err) {
			dispatch({
				type: types.SET_STATE,
				data: { verifyingToken: false, pending: false, errors: getResponseErrors(err) },
			});
			Cookies.remove('authToken');
			Cookies.remove('TS_User_type');
			Cookies.remove('TS_UserKey');
		}
	},

	submitEmail: (data) => async (dispatch) => {

		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {
			// Checks for vaild user and generate token email
			const results = await api.post('/account/verify-email',  {
				email: data
			});

			notification['success']({
				duration: 5,
				message: 'Check your email for further instructions.',
				description:
					'Email Sent',
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					pending: false,
					loadResetPasswordModal: true
				},
			});

		} catch(err) {
			dispatch({
				type: types.SET_STATE,
				data: {pending: false, loadResetPasswordModal: false, errors: getResponseErrors(err)},
			});
		}
	},

	// Reset Password on login page
	resetPassword: (email, password, code) => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {

			let res = await api.post('/account/reset_password', {"email": email, "password": password, "code": code});
			
			notification['success']({
				duration: 6,
				message: 'Update Success!',
				description:
					'Changed password successfully',
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
				},
			});

		} catch(err) {
			console.log(err)
			notification['error']({
				duration: 6,
				message: 'Update failed!',
				description:
					err.response.data.errors[0],
			});
			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
				},
			});
		}
	}

	
}; 
